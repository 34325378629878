<template>
	<div>

		<section id="performance" class="container">
			<div class="card-deck">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title"><img src="https://img.icons8.com/fluent/1x/helping-hand.png" class="img img-fluid" width="50"></h5>
						<h5 class="card-title mb-2"><b>Accompagnement d'un expert comptable physique</b></h5>
						<p class="card-text">Nous ne sommes pas un cabinet en ligne. Vous avez un accès directement à l'expert comptable.</p>
						<a href="#" class="btn btn-block">Card link</a>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<h5 class="card-title"><img src="https://img.icons8.com/flat_round/1x/medal.png" class="img img-fluid" width="50"></h5>
						<h5 class="card-title mb-2"><b>Service de qualité</b></h5>
						<p class="card-text">Bénéficier des conseils d'experts comptable expérimentés et pluridisciplinaire.</p>
						<a href="#" class="btn btn-block">Card link</a>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<h5 class="card-title"><img src="https://img.icons8.com/color/2x/guru.png" class="img img-fluid" width="50"></h5>
						<h5 class="card-title mb-2"><b>Etre Zen</b></h5>
						<p class="card-text">Confiez votre comptabilité et votre suivi administratif à des professionnel et concentrer sur le développement de votre activité.</p>
						<a href="#" class="btn btn-block">Card link</a>
					</div>
				</div>
			</div>
		</section>

		<section id="more">
			<div class="jumbotron container">
				<h3 class="jumbotron-title">Recevez plus d'informations.</h3>
				<p class="text-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit<br>sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br></p>
				<div class="input-group container w-50">
					<input type="text" class="form-control" required>
					<div class="input-group-append">
						<button class="input-group-text" type="button">Contactez nous</button>
					</div>
				</div>
			</div>
		</section>

	</div>
</template>

<script>

export default {
	name: 'Home',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	#performance{
		margin-bottom: 100px;
	}
	#performance .card-deck .card .card-text{
		text-align: justify;
	}
	#performance .card-deck .card{
		border-radius: 20px;
	}
	#performance .card-deck .card a{
		margin-top: 90px;
		border: white;
		border: 2px solid white;
		border-radius: 30px;
		color: white;
		font-size: 11pt !important;
	}
	#performance .card-deck .card:nth-child(1),#performance .card-deck .card:nth-child(3){
		background: white;
	}
	#performance .card-deck .card:nth-child(1) a,#performance .card-deck .card:nth-child(3) a{
		background: #DA4439;
		color: white;
	}
	#performance .card-deck .card:nth-child(2){
		background: #DA4439;
		color: white;
	}
	#performance .card-deck .card:nth-child(2) a{
		background: white;
		color: #DA4439;
	}

	#more{
		background: white;
		padding-top: 90px;
	}
	#more .jumbotron{
		background: none;
		color: #4E4E4E;
		text-align: center;
	}
	#more .jumbotron .jumbotron-title{
		text-transform: uppercase;
		font-weight: bold;
	}
	#more .jumbotron .jumbotron-title:after{
		content: "";
		display: block;
		margin: 0 auto;
		width: 50%;
		padding-top: 20px;
		border-bottom: 5px solid #DA4439;
	}
	#more .jumbotron .input-group input{
		width: 50%;
	}
	#more .jumbotron .input-group button{
		background: #DA4439;
		color: white;
		font-size: 11pt !important;
	}
</style>
